<template>
  <div class="px-10" data-testid="site-data-extract-page">
    <Breadcrumbs />

    <v-row>
      <v-col sm="6" class="pt-0">
        <div class="mb-2">
          <span class="text-patient-no mr-2">TRIAL {{ trialNumber }}</span>
        </div>

        <h4 class="page-title" data-testid="site-title">
          Report: Site {{ siteNo }}
        </h4>
      </v-col>
    </v-row>

    <ReportCard
      :site-no="siteNo"
      :report-type="reportType.AUDIT"
      report-context="site"
      :overall-selected="currentSelected"
      :show-patients-count="true"
      @active="setCurrentSelected"
      >Site Audit trail report</ReportCard
    >

    <ReportCard
      :site-no="siteNo"
      :report-type="reportType.USER_ACCESS"
      report-context="site"
      :overall-selected="currentSelected"
      @active="setCurrentSelected"
      >Site user access report</ReportCard
    >
  </div>
</template>

<script>
import Breadcrumbs from '@/components/layout/Breadcrumbs'
import ReportCard from '@/components/reports/ReportCard'
import trialSettingsGettersMixin from '@/components/mixins/store/trialSettingsGettersMixin'
import reportService from '@/services/site-report-service'
import reportType from '@/constants/reportType'

export default {
  name: 'Reports',
  mixins: [trialSettingsGettersMixin],
  provide() {
    return {
      reportService,
    }
  },

  components: { ReportCard, Breadcrumbs },
  props: { siteNo: { type: [String, Number], required: true } },
  data() {
    return { currentSelected: '', reportType }
  },
  methods: {
    setCurrentSelected(report) {
      this.currentSelected = report
    },
  },
}
</script>
