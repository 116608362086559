import dateTimeWithTimeZone from '@/utils/date/dateTimeWithTimeZone'
import Vue from 'vue'

export default {
  requestReport(reportType: string, siteNo: string) {
    return new Promise((resolve, reject) => {
      const currentClientTime = dateTimeWithTimeZone()

      Vue.$http
        .post(`/web/sites/${siteNo}/report/${reportType}`, {
          currentClientTime,
        })
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('requestReport error', error, {
            reportType,
            siteNo,
            currentClientTime,
          })
          reject(error)
        })
    })
  },
  getReports(reportType: string, siteNo: string) {
    return new Promise((resolve, reject) => {
      const currentClientTime = dateTimeWithTimeZone()

      Vue.$http
        .get(`/web/sites/${siteNo}/report/${reportType}`)
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('getReports error', error, {
            reportType,
            siteNo,
            currentClientTime,
          })
          reject(error)
        })
    })
  },
  downloadReport(reportType: string, reportId: number, siteNo: string) {
    return new Promise((resolve, reject) => {
      const currentClientTime = dateTimeWithTimeZone()

      Vue.$http
        .post(
          `/web/sites/${siteNo}/report/${reportType}/${reportId}/download`,
          { currentClientTime },
          { responseType: 'arraybuffer' }
        )
        .then(response => resolve(response.data))
        .catch(error => {
          Vue.$log.error(error)
          Vue.$tracking.error('downloadReport error', error, {
            reportType,
            currentClientTime,
            siteNo,
            reportId,
          })
          reject(error)
        })
    })
  },
}
